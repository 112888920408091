/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Container styles */
.body-section {
  width: 100%;
  height: 100%;
  background-image: url('https://wallpaperaccess.com/full/3056320.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 100px 0 50px 0;
  margin: 0 auto; /* Center the container */
  max-width: 1200px; /* Limit container width */
}

.container-part {
  width: 415px;
  height: 470px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  border: 2px solid black;
  margin: 30px;
}

.photo-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

.photo-container img {
  width: 100%;
  height: 410px;
  object-fit: cover;
  position: absolute;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.photo-name {
  margin-top: 415px;
  text-align: center;
  color: black;
}

.photo-name h2 a {
  font-size: 1.5rem;
  cursor: pointer;
}

.photo-name h2 a:hover {
  color: rgb(32, 25, 242);
}

.photo-container img:hover {
  border: 2px solid blue;
}

/* Tablet styles */
@media (max-width: 980px) {
  .container-part {
    width: 300px;
    height: 360px;
    margin: 20px; /* Adjust spacing */
  }

  .photo-container img {
    height: 300px;
  }
  .photo-name {
    text-align: center;
    margin-top: 310px; /* Adjust margin for mobile */
  }
  .photo-name h2 a {
    align-items: center;
    font-size: 1rem;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .container-part {
    width: 250px;
    height: 300px;
    margin: 15px; /* Adjust spacing */
  }

  .photo-name {
    text-align: center;
    margin-top: 260px; /* Adjust margin for mobile */
  }
  .photo-name h2 a {
    align-items: center;
    font-size: 1rem;
  }
  .photo-container img {
    height: 250px;
  }
}

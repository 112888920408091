/* Default styles */
.footer-container {
  background-color: #a3aa9c;
  /* padding: 20px; */
}

.top-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
}

.contact,
.map {
  flex-basis: 45%;
}

.map {
  text-align: center;
}
.map h2 {
  margin-left: 90px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  left: 0;
  text-align: center;
}

.social-icons a {
  justify-content: space-between;
  margin-right: 10px;
  color: #fff;
  margin: 20px;
}
.icons {
  height: 30px;
  width: 30px;
  /* margin-bottom: 20px; */
}

.facebook {
  color: #0866ff;
}

.instagram {
  border-radius: 50%;
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}

.icons:hover {
  color: rgb(34, 237, 183);
}

.topicondiv {
  display: flex;
}

.topicons {
  height: 25px;
  width: 25px;
  margin-right: 8px;
}
.contact .address {
  font-weight: bold;
  margin-bottom: 10px;
}
.contact .academy-name {
  margin-bottom: 15px;
}

.copyright {
  padding: 0;
  margin-top: 20px;
}
.copyright p {
  color: white;
  text-decoration: none;
}
.copyright p a {
  text-decoration: underline;
  color: whitesmoke;
}
.copyright p a:hover {
  color: rgb(74, 74, 230);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .top-footer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact,
  .map {
    flex-basis: 100%;
  }

  .social-icons {
    margin-top: 20px;
  }
  .map h2 {
    margin-top: 30px;
    margin-left: 180px;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    font-size: 14px;
  }
  .map h2 {
    margin-top: 20px;
    margin-left: 20px;
  }
}

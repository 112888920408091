/* SpiceInfo.css */
.spice-info-container {
  margin: 0px auto;
  max-width: 100%;
  padding: 20px;
  /* background-color: #fead6a; */
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #000000;
}

.spice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spice-photo {
  width: 100%;
  height: 300px;
  max-width: 60%;
  margin-bottom: 20px;
  border-radius: 10px;
}

.spice-details {
  text-align: left;
}

.section {
  margin-bottom: 30px;
}

.spice-info-container h1 {
  text-align: center;
  margin-bottom: 10px;
}
.spice-container h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.spice-container ul {
  list-style-type: disc;
  margin-left: 20px;
}
.spice-container ol {
  margin-left: 20px;
}

.spice-container li {
  margin-bottom: 5px;
  color: #000000;
}
@media (max-width: 480px) {
  .spice-photo {
    width: 100%;
    height: 250px;
    max-width: 90%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
}

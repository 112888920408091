body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.academy-header {
  position: sticky;
  top: 0;
  height: 150px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: whitesmoke;
  color: rgb(0, 0, 0);
}

.academy-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
.ie-academy-logo2 {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.ie-academy-logo2 img {
  width: 200px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add spacing between logo and text */
}

.ie-academy-logo2 h1 {
  font-size: 2rem;
  margin: 0;
}

.academy-logo h1 {
  font-size: 3rem;
  margin: 0;
}

.academy-logo img {
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add spacing between logo and text */
}

.academy-nav-links {
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 200px; /* Set your desired width */
  position: fixed;
  top: 80px;
  left: -200px; /* Off-screen initially */
  bottom: 0;
  background-color: whitesmoke;
  padding-top: 10px; /* Account for header height */
  transition: left 0.3s ease; /* Smooth transition effect */
}

.academy-nav-links.open {
  left: 0; /* Slide in from left when open */
}

.academy-nav-links a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 14px 16px;
  font-size: 17px;
}

.academy-nav-links a:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.academy-menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.caret-down-icon {
  margin-left: 5px;
  font-size: 13px;
}

.dropdown {
  position: relative;
}

.dropdown a {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: whitesmoke;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 200px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu a {
  padding: 10px 20px;
  display: block;
}

.dropdown-menu a:hover {
  background-color: black;
  color: white;
}

.sub-dropdown {
  position: relative;
}

.sub-dropdown-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 80%;
  background-color: whitesmoke;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}

.sub-dropdown:hover .sub-dropdown-menu {
  display: block;
}

/* IEHeader */
.IEdropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(207, 164, 164);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 150px;
}

.dropdown:hover .IEdropdown-menu {
  display: block;
}

.IEdropdown-menu a {
  padding: 10px 20px;
  display: block;
}

.IEdropdown-menu a:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

/* Mobile view */
@media (min-width: 769px) {
  .academy-menu-icon {
    display: none; /* Hide hamburger icon on larger screens */
  }

  .academy-nav-links {
    display: flex;
    flex-direction: row;
    position: static;
    background-color: transparent;
    padding-top: 0;
    left: 0;
    width: auto;
  }

  .academy-nav-links a {
    padding: 5px 10px; /* Adjust spacing */
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: whitesmoke;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 980px) {
  .academy-header {
    height: 80px;
  }

  .academy-logo h1 {
    display: none;
  }

  .academy-logo img {
    width: 180px;
  }

  .ie-academy-logo2 h1 {
    display: none;
  }
  .ie-academy-logo2 img {
    width: 250px;
  }
}

@media (max-width: 480px) {
  .academy-header {
    height: 80px;
  }

  .academy-logo h1 {
    display: none;
  }

  .academy-logo img {
    width: 180px;
  }

  .academy-nav-links a {
    font-size: 20px;
  }
  .ie-academy-logo2 h1 {
    display: none;
  }
  .ie-academy-logo2 img {
    width: 250px;
  }
}

/* AcademyHome.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
  max-width: 100%;
  margin: 0px auto;
  /* padding: 50px; */
  padding-top: 50px;
  background-color: #5fa9ef;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #000000;
}

.container header {
  text-align: center;
  margin-bottom: 30px;
}

.about h1 {
  margin-bottom: 10px;
}

.about p {
  margin-bottom: 20px;
}

.container main {
  margin-bottom: 40px;
}

.container section {
  margin-bottom: 40px;
}

.about h2 {
  margin-bottom: 10px;
}

.qualifiction ul {
  padding: 0;
}

.qualifiction li {
  list-style-type: disc;
  margin-bottom: 10px;
}
.courses ul {
  padding: 0;
}

.courses li {
  list-style-type: disc;
  margin-bottom: 10px;
}

/* App.css */
.App {
  text-align: center;
}

.popup {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  width: 300px;
}

form div {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 1px;
}

input,
textarea {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

button {
  margin-right: 10px;
  padding: 8px 16px;
}

/* Apps.css  for register form*/
.Apps {
  text-align: center;
  margin-top: 50px;
}

.register-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.register-form div {
  margin-bottom: 15px;
}

.register-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.register-form input[type='text'],
.register-form input[type='email'],
input[type='tel'],
select,
input[type='number'] {
  width: calc(100% - 22px);
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.register-form select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 9l6 6 6-6"></path></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  padding-right: 20px;
}

.register-form button {
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register-form button:hover {
  background-color: #0056b3;
}

/* Contact.css */

.contact-container {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}
.contact-container h1 {
  text-align: center;
  margin-bottom: 50px;
}

.company-container {
  margin-left: 50px;
  margin-bottom: 30px;
}

.company-container h2 {
  color: #333;
  margin-bottom: 10px;
}

.company-container strong p {
  margin-bottom: 20px;
}

.company-container ul p {
  margin-left: 10px;
  line-height: 1.3;
}

.company-container p {
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding: 15px;
  }
}

/* Reset and Global Styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar {
  width: 1.3rem;
} */

::-webkit-scrollbar-thumb {
  /* border-radius: 1rem; */
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  background: var(--bg);
  font-size: 16px; /* Default font size */
}

/* Container Styles */
.spicessss-container {
  max-width: 65rem;
  padding: 2rem 0.5rem;
  margin: 0 auto;
  border-radius: 20px;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}

/* Heading Styles */
.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

/* Swiper Styles */
.swiper_container {
  height: 33rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 20rem;
  height: 25rem;
  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  object-fit: cover;
}

/* Slider Control Styles */
.slider-controler {
  position: relative;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next,
.slider-controler .swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}
.slider-controler .swiper-button-next:hover {
  background-color: red;
}
.slider-controler .swiper-button-prev:hover {
  background-color: red;
}

.slider-controler .swiper-button-next {
  right: 2rem; /* Adjust distance from the right side */
}

.slider-controler .swiper-button-prev {
  left: 2rem; /* Adjust distance from the left side */
}

/* Media Queries */

@media (max-width: 767px) {
  .spicessss-container {
    max-width: 95%;
    padding: 1rem;
    border-radius: 15px;
  }

  .swiper_container {
    height: 20rem;
  }

  .swiper-slide {
    width: 100%;
    height: 20rem;
  }

  .swiper-slide img {
    border-radius: 1rem;
  }

  .heading {
    margin-bottom: 0;
    font-size: 2.5rem;
  }

  .slider-controler .swiper-button-next {
    right: 2%; /* Adjust position from the right side */
    transform: translateX(0); /* Reset translate */
  }

  .slider-controler .swiper-button-prev {
    left: 2%; /* Adjust position from the left side */
    transform: translateX(0); /* Reset translate */
  }
}

@media (max-width: 480px) {
  .spicessss-container {
    max-width: 95%;
    padding: 1rem;
    border-radius: 15px;
  }

  .swiper_container {
    height: 20rem;
  }

  .swiper-slide {
    width: 100%;
    height: 20rem;
  }

  .swiper-slide img {
    border-radius: 1rem;
  }

  .heading {
    margin-bottom: 0;
    font-size: 2.5rem;
  }

  .slider-controler .swiper-button-next {
    right: 2%; /* Adjust position from the right side */
    transform: translateX(0); /* Reset translate */
  }

  .slider-controler .swiper-button-prev {
    left: 2%; /* Adjust position from the left side */
    transform: translateX(0); /* Reset translate */
  }
}

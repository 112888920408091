/* EvaIandE.css */

.spices-gallary {
  min-height: 60vh;
  width: 100%;
  padding-bottom: 20px;
  background-color: rgb(221, 198, 198);
}
.spices-container {
  margin: 0px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}

.spices-box {
  background-color: #e5ebe0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.spices-box img {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgb(235, 229, 229), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.spices-box img:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.1);
  box-shadow: 0px 2px 4px rgba(52, 45, 45, 0.99);
}

.spices-box h3 {
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}
.spices-box h3 a {
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}
.spices-box h3 a {
  color: black;
  text-decoration: none;
}
.spices-box h3 a:hover {
  color: rgb(86, 170, 243);
}

.spices-box p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* animation css */

/* PhotoGallery.css */

@media screen and (max-width: 768px) {
  .spices-container {
    margin: 0;
    padding: 0;
  }
}

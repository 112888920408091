/* EvaIandEHome.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #000000;
}

.ie-home {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.ie-home header {
  text-align: center;
  margin-bottom: 30px;
}

.welcome-section h1 {
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
  font-size: 2rem;
}

.welcome-section p {
  color: #000000;
  margin-bottom: 20px;
}

.ie-home main {
  margin-bottom: 40px;
}

.ie-home section {
  margin-bottom: 40px;
}

.services-section h2,
.global-reach-section h2,
.contact-section,
h2 li {
  color: #000000;
  margin-bottom: 10px;
}

.services-section .service h3 {
  color: #000000;
  margin-bottom: 10px;
}

/* /IEHomePhoto/ */

.ppppppppp {
  width: 100%;
  height: 100vh;
}

@media (max-width: 769px) {
  .ppppppppp {
    height: 400px;
  }
}

/* EvaIandE.css */

.our-products {
  background-image: linear-gradient(rgb(41, 40, 41), rgb(234, 192, 9));
  padding-bottom: 50px;
}
.our-products h1 {
  text-align: center;
  color: white;
  padding-top: 20px;
}
.our-products p {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
.product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(100px, 0.5fr)); */
  gap: 20px;
}

.product-box {
  background-color: #e5ebe0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.product-box img {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgb(235, 229, 229), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product-box img:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.12);
  box-shadow: 0px 2px 4px rgba(52, 45, 45, 0.99);
}

.product-box h3 {
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}
.product-box h3 a {
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}
.product-box h3 a {
  color: black;
  text-decoration: none;
}
.product-box h3 a:hover {
  color: rgb(86, 170, 243);
}

.product-box p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Enquiry.jsx css */
.enquiry-form-container {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}

.enquiry-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type='submit'] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #0056b3;
}
/* Enquiry.jsx css end */

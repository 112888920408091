/* About.css */

.about-section {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}

.about-section h2 {
  color: #333;
  margin-bottom: 20px;
}

.about-section h3 {
  color: #333;
  margin-bottom: 10px;
}

.about-section p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.about-section ol,
ul {
  margin-bottom: 15px;
  margin-left: 20px;
}

.about-section li {
  margin-bottom: 5px;
}

strong {
  color: #000000;
}

@media screen and (max-width: 768px) {
  .about-section {
    padding: 15px;
  }
}
